const message = 'Campo é obrigatório.'
// eslint-disable-next-line
const value = 'Digite um valor.'

export default {
  errorRefDate ()  {
    const errors = []
    if (!this.$v.item.refDate.$dirty) return errors
    !this.$v.item.refDate.required && errors.push(message)
    this.item.refDate === 'Invalid Date' && errors.push(message)
    return errors
  },
  errorSalesReadjustmentIndex ()  {
    const errors = []
    if (!this.$v.item.salesReadjustmentIndex.$dirty) return errors
    !this.$v.item.salesReadjustmentIndex.required && errors.push(message)
    return errors
  },
  errorDebitIndex ()  {
    const errors = []
    if (!this.$v.item.debitIndex.$dirty) return errors
    !this.$v.item.debitIndex.required && errors.push(message)
    return errors
  },
  errorMinimumResellerSalesValue ()  {
    const errors = []
    if (!this.$v.item.minimumResellerSalesValue.$dirty) return errors
    !this.$v.item.minimumResellerSalesValue.required && errors.push(message)
    return errors
  }
}

